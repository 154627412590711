import * as React from "react"
import PropTypes from "prop-types"
import useActions from "./actions"

import reducer, { initialState } from "./reducer"

let CartContext = React.createContext({
  cart: initialState,
  actions: {
    addToCart: () => null,
    removeFromCart: () => null,
    clearCart: () => null,
  },
})

function CartContextProvider({ children }) {
  let [cart, dispatch] = React.useReducer(reducer, initialState)

  const actions = useActions(cart, dispatch)

  return (
    <CartContext.Provider value={{ cart, actions }}>
      {children}
    </CartContext.Provider>
  )
}

CartContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

let CartContextConsumer = CartContext.Consumer

export { CartContext, CartContextProvider, CartContextConsumer }
