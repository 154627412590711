import React from "react"
import PropTypes from "prop-types"
import { toast } from "react-toastify"

import { CartContextProvider } from "./src/contexts/Cart"
import { StoreContextProvider } from "./src/contexts/Store"

import "react-image-lightbox/style.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-toastify/dist/ReactToastify.css"
import "./src/styles/theme/index.scss"
import "./src/styles/base.scss"

toast.configure({
  autoClose: 4000,
  hideProgressBar: true,
  position: "top-center",
  draggable: true,
  closeOnClick: true,
  theme: "colored",
})

export const wrapRootElement = ({ element }) => (
  <CartContextProvider>
    <StoreContextProvider>{element}</StoreContextProvider>
  </CartContextProvider>
)

wrapRootElement.propTypes = {
  element: PropTypes.isRequired,
}
