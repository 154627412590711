import {
  SET_CATEGORIES,
  SET_DELIVERY_CITIES,
  SET_FILTERED_PRODUCTS,
  SET_PRODUCTS,
  SET_SEARCH_STRING,
} from "./types"

export const initialState = {
  products: [],
  categories: [],
  filteredProducts: [],
  deliveryCities: [],
  searchString: "",
}

let reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return { ...state, products: action.payload }
    case SET_CATEGORIES:
      return { ...state, categories: action.payload }
    case SET_FILTERED_PRODUCTS:
      return { ...state, filteredProducts: action.payload }
    case SET_DELIVERY_CITIES:
      return { ...state, deliveryCities: action.payload }
    case SET_SEARCH_STRING:
      return { ...state, searchString: action.payload }
    default:
      return { ...state }
  }
}

export default reducer
